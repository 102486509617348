@charset "UTF-8";

@import url(//cdn.jsdelivr.net/combine/gh/necolas/normalize.css@8.0.1/normalize.min.css,gh/heartyme/web.hearty.me@41214/css/jquery.mmenu.custom.min.css,gh/fabien-d/alertify.js@0.3.11/themes/alertify.core.min.css,gh/heartyme/web.hearty.me@41214/css/alertify.custom.min.css);
@import url(//cdn.jsdelivr.net/gh/hung1001/font-awesome-pro@v5.15.4/css/all.min.css);

/* Comfortaa */
@import url(//fonts.googleapis.com/css?family=Comfortaa);

/* 台北黑體：git.io/JmrjB */
@import url(//cdn.jsdelivr.net/gh/vdustr/taipei-sans-tc@v0.1.1/packages/core/dist/Regular/TaipeiSansTCBeta-Regular.min.css);

/* 霞鶩文楷：git.io/Jmrj5 & git.io/JmuZv */
@import url(//cdn.jsdelivr.net/npm/lxgw-wenkai-webfont@1.7.0/lxgwwenkaimono-bold.min.css);

/* 源樣明體：git.io/Jmrj5 */
@import url(//cdn.jsdelivr.net/gh/4hxcrpr7wbqz/genyo-font-web@1.501/GenYoMinTW-SB.min.css);

/* git.io/JJiY0 
@font-face{
	font-family: notoserif-tc;
	src: url(//cdn.jsdelivr.net/gh/googlefonts/chinese@f8be900af3e1ce347e45cee888d89ed57aef675f/fonts/NotoSerif/NotoSerifCJKtc-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/CodePlayer/webfont-noto@7af038d32866b387c64ef8e644abf5c303e48db6/dist/NotoSerif/NotoSerifCJKtc-hinted/subset/NotoSerifCJKtc-hinted-standard/NotoSerifCJKtc-Medium.woff) format("woff");
}
*/

*, 
*::before, 
*::after{
	box-sizing: inherit;
}
html{
	box-sizing: border-box;
	appearance: none;
}
html, 
body{
	scroll-behavior: smooth;
	overscroll-behavior: none;
}
body{
	font-family: "Comfortaa", "Taipei Sans TC", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "\5FAE\8EDF\6B63\9ED1\9AD4", "Microsoft JhengHei", "\5FAE\8F6F\96C5\9ED1", "Microsoft Yahei";
	font-size: medium;
	font-smooth: always;
	text-rendering: optimizeSpeed;
	margin: 0 auto;
	padding: 0;
	height: 100vh;
	background: #fff;
	-webkit-touch-callout: none;
}
	.mask, 
	body.loading::after{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.mask{
		display: none;
		background: rgba(0,0,0,.75);
		z-index: 2;
	}
	body.loading::after{
		content: "";
		background: url(//cdn.jsdelivr.net/gh/heartyme/web.hearty.me@41214/img/kitten.png) no-repeat center, 
			linear-gradient(rgba(255,255,255,.65), rgba(255,255,255,.65));
		background-size: 250px;
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		cursor: wait;
		pointer-events: none;
		z-index: 3;
	}

[onclick]{
	cursor: pointer;
}

.pace{
	user-select: none;
	pointer-events: none;
}
	.pace-inactive{
		display: none;
	}
	.pace .pace-progress{
		position: fixed;
		width: 100%;
		top: 0;
		right: 100%;
		height: 2px;
		background: #f16d6e;
		z-index: 9;
	}

/*
	git.io/JvNy1
	git.io/JJKit
*/
@font-face{
	font-family: openhuninn;
	/* v1.20 */
	src: url(//cdn.jsdelivr.net/gh/max32002/FakePearl@1.1/webfont/FakePearl-Regular.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/FakePearl@1.1/webfont/FakePearl-Regular.woff) format("woff");
	font-display: fallback;
}
@font-face{
	font-family: wind;
	/* v2.001 */
	src: url(//s.hearty.app/fonts/wind/wind-regular.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: jinxuan;
	/* v2.4 */
	src: url(//s.hearty.app/fonts/jinxuan/jf-jinxuan-medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/relabcc/ntb-invoice@692266bc195ebeb79b71c50c047634fb362abc5e/src/web-font/minified/jf-jinxuan-fresh2.2-regular.woff) format("woff");
	font-display: swap;
}
@font-face{
	font-family: cream;
	/* v1.1 */
	src: url(//s.hearty.app/fonts/cream/cream.woff2) format("woff2");
	font-display: swap;
}
/* git.io/JmuCe */
@font-face{
	font-family: yozai;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/yozai.woff2) format("woff2");
	font-display: swap;
}
/* git.io/JvNv9 */
@font-face{
	font-family: naikai;

	/* v1.89 */
	src: url(//cdn.jsdelivr.net/gh/max32002/naikaifont@1.89/webfont/NaikaiFont-Regular-Lite.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/naikaifont@1.89/webfont/NaikaiFont-Regular-Lite.woff) format("woff");
	font-display: swap;
}
/*
git.io/JvNvS
git.io/JJKKc
*/
@font-face{
	font-family: jasonwriting2;
	/* v1.6 */
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting2-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting2-Medium.woff) format("woff");
	font-display: swap;
}
@font-face{
	font-family: jasonwriting1;
	/* v1.48 */
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting1-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting1-Medium.woff) format("woff");
	font-display: swap;
}
@font-face{
	font-family: jasonwriting4;
	/* v1.01.21 */
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting4-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting4-Medium.woff) format("woff");
	font-display: swap;
}
@font-face{
	font-family: jasonwriting5;
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting5-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting5-Medium.woff) format("woff");
	/* v1.01.21 
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20220828/webfont/JasonHandwriting5.woff2) format("woff2");
	*/
	font-display: swap;
}
@font-face{
	font-family: jasonwriting6;
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting6-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting6-Medium.woff) format("woff");
	/* 2022/12/25 v1.00
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/jasonhandwriting6.woff2) format("woff2");
	*/
	font-display: swap;
}
@font-face {
	/* v1.071 */
	font-family: kurewa;
	src: url(//cdn.jsdelivr.net/gh/max32002/kurewa-gothic@1.071/webfont/CJK%20TC/KurewaGothicCjkTc-SemiBold.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/kurewa-gothic@1.071/webfont/CJK%20TC/KurewaGothicCjkTc-SemiBold.woff) format("woff");
	font-display: swap;
}
/* git.io/JmzMm */
@font-face{
	font-family: nanifont;
	/* v1.036 */
	src: url(//cdn.jsdelivr.net/gh/max32002/nanifont@1.036/webfont/NaniFont-Regular.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/nanifont@1.036/webfont/NaniFont-Regular.woff) format("woff");
	font-display: swap;
}
/* git.io/J1uSc */
@font-face {
	/* v2.145 */
	font-family: pop-gothic;
	src: url(//cdn.jsdelivr.net/gh/max32002/pop-gothic@45bf2dd76fbbded2c69cfcc0e6279ef4773711ce/webfont/CJK%20TC/PopGothicCjkTc-SemiBold.woff2) format("woff2");
	font-display: swap;
}
/* home.gamer.com.tw/creationDetail.php?sn=4470108 */
@font-face{
	font-family: starlovepencil;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/starlovepencil.woff2) format("woff2");
	font-display: swap;
}
/* maoken.com/freefonts/9288.html */
@font-face{
	font-family: huiwen;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/huiwen.woff2) format("woff2");
	font-display: swap;
}
/* bit.ly/ipencrane */
@font-face{
	font-family: ipencrane;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/ipencrane.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: lisu;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/lisu.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: yankai;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/yankai.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: weibei;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/weibei.woff2) format("woff2");
	font-display: swap;
}

/* 以下停用  */
@font-face{
	font-family: jasonwriting3;
	/* v1.24 */
	src: url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting3-Regular.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/JasonHandWritingFonts@20240409/webfont/JasonHandwriting3-Regular.woff) format("woff");
	font-display: swap;
}
/* maoken.com/freefonts/8199.html */
@font-face{
	font-family: caramel;
	src: url(//cdn.jsdelivr.net/gh/chennien/f.hearty.app@1/caramel.woff2) format("woff2");
	font-display: swap;
}
/* git.io/JvN9I */
@font-face{
	font-family: bakudai;

	/* v1.49 */
	src: url(//cdn.jsdelivr.net/gh/max32002/bakudaifont@1.49/webfont/Bakudai-Medium.woff2) format("woff2"), url(//cdn.jsdelivr.net/gh/max32002/bakudaifont@1.49/webfont/Bakudai-Medium.woff) format("woff");
	font-display: swap;
}
@font-face{
	font-family: wind;
	/* v2.001 */
	src: url(//s.hearty.app/fonts/wind/wind-regular.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: fist;
	src: url(//s.hearty.app/fonts/fist/fist.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	/* v1.1 */
	font-family: lihsianti;
	src: url(//s.hearty.app/fonts/lihsianti/lihsianti-monospaced.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: oyeh;
	/* v1.5 */
	src: url(//s.hearty.app/fonts/oyeh/oyeh.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: liao;
	src: url(//s.hearty.app/fonts/liao/liao-monospaced.woff2) format("woff2");
	font-display: swap;
}
@font-face{
	font-family: drechi;
	src: url(//s.hearty.app/fonts/drechi/drechi-monospaced.woff2) format("woff2");
	font-display: swap;
}

[data-font="openhuninn"]{
	font-family: "openhuninn", "Taipei Sans TC", system-ui;
}
[data-font="taipeisans"]{
	font-family: "Taipei Sans TC", system-ui;
}
[data-font="jinxuan"]{
	font-family: "jinxuan", "Taipei Sans TC", system-ui;
}
[data-font="cream"]{
	font-family: "cream", "Taipei Sans TC", system-ui;
}
[data-font="wenkai"]{
	font-family: "LXGW WenKai Mono", "Taipei Sans TC", system-ui;
}
[data-font="yozai"]{
	font-family: "yozai", "Taipei Sans TC", system-ui;
}
[data-font="naikai"]{
	font-family: "naikai", "Taipei Sans TC", system-ui;
}
[data-font="jasonwriting2"]{
	font-family: "jasonwriting2", "Taipei Sans TC", system-ui;
}
[data-font="jasonwriting1"]{
	font-family: "jasonwriting1", "Taipei Sans TC", system-ui;
}
[data-font="jasonwriting4"]{
	font-family: "jasonwriting4", "Taipei Sans TC", system-ui;
	letter-spacing: 1px;
}
[data-font="jasonwriting5"]{
	font-family: "jasonwriting5", "Taipei Sans TC", system-ui;
}
[data-font="jasonwriting6"]{
	font-family: "jasonwriting6", "Taipei Sans TC", system-ui;
}
[data-font="kurewa"]{
	font-family: "kurewa", "openhuninn", "Taipei Sans TC", system-ui;
	letter-spacing: .5px;
}
[data-font="nanifont"]{
	font-family: "nanifont", "Taipei Sans TC", system-ui;
}
[data-font="pop-gothic"]{
	font-family: "pop-gothic", "openhuninn", "Taipei Sans TC", system-ui;
}
[data-font="starlovepencil"]{
	font-family: "starlovepencil", "openhuninn", "Taipei Sans TC", system-ui;
}
[data-font="genyo"]{
	font-family: "GenYoMin TW Web", "Taipei Sans TC", system-ui;
}
[data-font="huiwen"]{
	font-family: "huiwen", "Taipei Sans TC", system-ui;
}
[data-font="ipencrane"]{
	font-family: "Times New Roman", "ipencrane", "Taipei Sans TC", system-ui;
}
[data-font="lisu"]{
	font-family: "lisu", "Taipei Sans TC", system-ui;
}
[data-font="yankai"]{
	font-family: "yankai", "Taipei Sans TC", system-ui;
}
[data-font="weibei"]{
	font-family: "weibei", "Taipei Sans TC", system-ui;
}
[data-font="bakudai"]{
	font-family: "bakudai", "Taipei Sans TC", system-ui;
}

/* iOS 無法處理過大的 Webfont */
@supports (-webkit-touch-callout: none){
	[data-font="jasonwriting2"], /* 清松 ABC、匯文古印、何某手寫 */
	[data-font="jasonwriting1"], 
	[data-font="jasonwriting4"], 
	[data-font="huiwen"], 
	[data-font="nanifont"]{
		font-family: "openhuninn", "Taipei Sans TC", system-ui;
	}
}

/* 以下停用  */
[data-font="jasonwriting3"]{
	font-family: "jasonwriting3", "Taipei Sans TC", system-ui;
	font-size: large;
	line-height: 1 !important;
	letter-spacing: -2px !important;
}
[data-font="caramel"]{
	font-family: "caramel", "Taipei Sans TC", system-ui;
}
[data-font="wind"]{
	font-family: "wind", "Taipei Sans TC", system-ui;
	letter-spacing: 3px !important;
}
[data-font="fist"]{
	font-family: "fist", "Taipei Sans TC", system-ui;
}
[data-font="lihsianti"]{
	font-family: "lihsianti", "Taipei Sans TC", system-ui;
	font-size: large;
}
[data-font="oyeh"]{
	font-family: "oyeh", "Taipei Sans TC", system-ui;
}
[data-font="liao"]{
	font-family: "liao", "Taipei Sans TC", system-ui;
}
[data-font="drechi"]{
	font-family: "drechi", "Taipei Sans TC", system-ui;
}


[data-fal]::before, 
[data-far]::before, 
[data-fas]::before, 
[data-falr]::after, 
[data-farr]::after, 
[data-fasr]::after{
	font-family: "Font Awesome 5 Pro", "openhuninn";
	font-weight: 500;
}
[data-fab]::before, 
[data-fabr]::after{
	font-family: "Font Awesome 5 Brands", "openhuninn";
	font-weight: 500;
}
[data-fad]::before, 
[data-fadr]::after{
	font-family: "Font Awesome 5 Duotone", "openhuninn";
	font-weight: 500;
}
	[data-fal]::before, 
	[data-far]::before, 
	[data-fas]::before, 
	[data-fad]::before, 
	[data-fab]::before{
		margin-right: .4em;
	}
	[data-falr]::after, 
	[data-farr]::after, 
	[data-fasr]::after, 
	[data-fadr]::after, 
	[data-fabr]::after{
		margin-left: .4em;
	}

[data-fab]::before{
	content: attr(data-fab);
}
[data-fabr]::after{
	content: attr(data-fabr);
}
[data-fad]::before{
	content: attr(data-fad);
}
[data-fadr]::after{
	content: attr(data-fadr);
}
[data-fal]::before{
	content: attr(data-fal);
	font-weight: 300;
}
[data-falr]::after{
	content: attr(data-falr);
	font-weight: 300;
}
[data-far]::before{
	content: attr(data-far);
}
[data-farr]::after{
	content: attr(data-farr);
}
[data-fas]::before{
	content: attr(data-fas);
	font-weight: 900;
}
[data-fasr]::after{
	content: attr(data-fasr);
	font-weight: 900;
}

/* Firefox */
input[type="email"], 
input[type="url"], 
input[type="tel"], 
input[type="number"]{
	ime-mode: disabled;
}
	input[type="number"]::-webkit-inner-spin-button, 
	input[type="number"]::-webkit-outer-spin-button{ 
		-webkit-appearance: none;
	}

input[type="checkbox"]{
	transform: scale(1.1);
}

/* 電腦 & not iPad Pro Portrait */
@media only screen and (min-width: 1025px) and (orientation: landscape){
	.tablet:not(.desktop), 
	.mobile:not(.desktop), 
	[landscape_disabled]{
		display: none !important;
	}
}
/* 手機 */
@media screen and (max-width: 767px){
	.desktop:not(.mobile), 
	.tablet:not(.mobile){
		display: none !important;
	}
		[landscape_disabled]{
			display: none;
		}
		@media screen and (min-aspect-ratio: 13/9){
			[landscape_disabled]{
				display: initial;
			}
		}
}
/* 平板專用 */
@media only screen and (min-width: 768px) and (max-width: 1024px){
	.desktop:not(.tablet), 
	.mobile:not(.tablet), 
	[landscape_disabled]{
		display: none !important;
	}
}
